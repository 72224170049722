<template>
    <PageLayout title="Quests" linkDefault1="/" :backButton="true">
        <div class="m-5  space-y-3">
            <Quests v-for="(_quest, i) in quests" :key="i" :current-number="_quest.quests_progresses.size()"
                :number-of-times="_quest.number_of_times" :name="_quest.name" />
        </div>
    </PageLayout>
</template>

<script>
import pageLayout from "../../components/base/pageLayout.vue";
import Quests from '../../components/Feed/Quests.vue'
import { getProfile } from '../../helpers/API/Feeds/User';
import { getQuests } from '../../helpers/API/Feeds/Quests';
export default {
    components: { pageLayout,Quests },
    data() {
        return {
            quests: [],
            user: null,
        };
    },

    created() {
        this.getUser();

    },
    methods: {
        async getUser() {
            await getProfile().then(({ data }) => {
                this.user = data.user_profile.id;
                console.log(this.user);
            })
            getQuests(this.user);
        },
        async Questss() {
            await getQuests(id).then(data => {
                console.log(data);
            })

        }

    }
};
</script>