<template>
    <div class="h-full rounded-3xl shadow-lg hover:shadow-2xl transition duration-300 ease-out w-full"
        :class="{ 'p-6': padding }">
        <div class="w-full flex-col flex justify-between items-center space-y-4">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        padding: {
            type: Boolean,
            default: true
        }
    }
}
</script>