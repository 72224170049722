<template>
  <Card>
    <div class="flex flex-row items-center justify-between w-full">
      <p class="font-poppins font-medium capitalize text-base leading-6">{{ name }}</p>
      <n-rate :size="16" color="#ff8303" readonly :default-value="currentNumber" :count="numberOfTimes" />
    </div>
    <div class="flex flex-row w-full">
      <NProgress :height="22" style="width: 100%;" indicator-placement="inside" :show-indicator="true"
        :color="themeVars.primaryColor" :percentage="percentage" />
    </div>
  </Card>
</template>

<script>
import { NRate, NProgress, useThemeVars } from "naive-ui";
import Card from "./Card.vue";

export default {
  components: {
    NRate,
    NProgress,
    Card,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    numberOfTimes: {
      type: Number,
      required: true,
    },
    currentNumber: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      percentage: null
    };
  },
  setup() {
    return {
      themeVars: useThemeVars(),
    };
  },
  created() {
    this.percentage = (this.currentNumber / this.numberOfTimes) * 100;
  },
}
</script>
